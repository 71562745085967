import PageHeader from "../../shared-components/page-header/page-header";
import React from "react";

export default function Childhood() {
  return (
    <>
      <PageHeader pageTitle={"Childhood"} />
      <div className={"instruction-p"}>
        <p>
          Born in Mississippi, I've never had the feeling like I was born and
          bred in The South. That could be due to the fact of being relocated to
          San Diego before I had a chance to take in the scenery but that was
          out of my control. After settling down in San Diego, I began to
          understand myself and my surroundings. First bike ride, fallen knee,
          memories of going to Subway and getting the Seafood and Crab Kids
          Meal, a treat us 90's babies got to live. Life was good growing up in
          California. Moreover, my parents finally gave me a partner in crime
          via a little sister. I had an older sister that taught me plenty, but
          now I got to be the older sibling.
        </p>
        <p>
          Soon after my younger sister was born, we relocated to Philadelphia.
          The city life took a little adjustment from seeing the Pacific Ocean
          every day, but my cousin showed me the ropes of getting around in and
          being a boy in Philadelphia. School was starting to become tedious with
          homework and schoolwork, no more arts and crafts all day. Even though
          there was more work to do, I didn't feel challenged majority of the
          time. So, what does a boy do when he is bored? TROUBLE. I remember
          having my mom called to the school at least once a year for minor
          incidents I happened to find myself get into while attending school.
          For instance, I love the story of bringing matches to school, source
          will be left anonymous, and lighting leaves on fire at the playground.
          No, I didn't burn the school down, a kid had told on me before I could
          get that far. However, when sent to the principal's office for a
          search and seize, there was nothing to be found and I was sent on my
          way. Maybe if the principal wasn't so busy and left me sitting in
          those cushioned chairs outside his office for so long, I wouldn't have
          thought of a way of hiding the matches in the cushion. Shrugs. Even
          though I knew how to find trouble, I managed to maintain good grades
          throughout school. I even made a substitute mad one time because the
          math was wrong. When I corrected her, she told me to go the
          principal's office; along with some other words. I don't look down on her
          for her anger anymore, I've learned that everyone has their own anger
          issues, including myself.
        </p>
        <p>
          After reaching the end of our lease in Philadelphia, at least it seemed
          liked a lease as frequent as we moved, we headed to good ol' North
          Carolina. The memories here will forever live with me, including the
          vivid memories of 9/11. While stationed in North Carolina, I had
          experiences of all sorts from at once labelled gifted and placed into
          a STEM program led by Duke University to understanding why the
          Confederate Flag and its meaning for my middle school. The memories I
          believe that is a cornerstone to who am I today though, would be the
          time another uncle spent living with us and just teaching me about
          life. At the time the seemed minimal, but I still reflect on them til
          today. Before leaving North Carolina, my dad was excited, nervous, and
          had the feeling of accomplishment as he was preparing to turn in his
          papers and retire after serving his country for 24 years. Funny he
          got to end his career near the same area he went to boot camp to start
          it all, Paris Island. But before he could retire, there was one last
          task, managing 9/11. The day it happened; all I remember is being at
          school with a couple of teachers because all the kids had left.
          Channel One only showed before school started. When my mom finally was
          able to pick me up, she was quiet. Reflecting on the day now, my
          dad explains how Camp Legune was one of the few air strips that could
          handle the load of air traffic preparing to land after all aircraft
          were instructed to land immediately. Given his duty was over Flight
          Ops, he had to ensure all aircraft that was headed to Camp Legune was
          legit and made it safely. I didn't know the work put in by my parents
          to make it seem like all was going to be ok. When my dad finally
          retired that December on my birthday, it was the close of one chapter
          of our lives, and the start of another. We were headed to Good Ol'
          Rocky Top.
        </p>
        <p>
          Tennessee, never seen more trees in my life. Also, never heard of this
          language they speak either. And everywhere I went, my mom kept telling
          me "That's family". Life was very different here. No more city life
          like Philly. No more oceans like Cali. No more hoops like Tar Heel
          nation. Here, life moved slow, steady, and peaceful; or, at least
          seemed to be that way. Tennessee was the place I met my first
          girlfriend, also my first rejection (she didn't know I was playing my
          cards so she would be my wife later), my first sport, my first
          accident, and much more. But as many teens do, I also fell off the
          wagon with school. Remember that anger problem, it surfaced early and
          often. I was in trouble for everything, vandalism, stealing, and
          fighting to say the least; but that's only the stuff they caught me
          doing. At one point though, anger wasn't in my control anymore. I
          remember those sit down talks with the psychologist about walking
          through my day and steps to take when I feel my breathing is shorter
          and vision is narrow. Was it fun? Heck yes, I got candy every visit.
          Once again though, I didn't know how these meetings were affecting me
          for the long term. Til today, I follow the practices I learned here
          (when given the time and space). As mentioned, I also began sports in
          Tennessee. It wasn't until halfway through high school when I tried
          to see if I was any good at this game called football. While playing
          football, I was able to maintain my anger it seemed; or, allowing it
          to be dormant like a volcano. I started transferring my image from
          being the band kid with the saxophone to one of the varsity football
          players. Then reality check, SCHOOL. Biology wasn't my thing; I was
          all about numbers. Math and Analytical work put me in the zone;
          however, the rest I needed assistance. Most classes I found a way to
          push myself through, but 9th grade Biology wasn't making the cut.
          Final Exam in Biology and I lost it. Maybe it was my parents
          threatening to take away football if I didn't get the grades like they
          wanted. Who Knows?!! But that day, Anger took over. I cussed the
          teacher out on the final exam. Not during. On!!! After receiving that
          lovely via his own final exam paper, I pretty much had one option,
          find a new school. My parents then transferred me to the school where
          they were teaching. Just great, 2 former Marines are not only my
          parents but also my teachers. Oh, Sweet Baby Jesus, let us pray. Life
          took time adjusting in the new environment with no football. TSSAA had
          deemed me ineligible because I had transferred schools; however, after
          that school year they made a rule that students transferring to a
          school where their parents work will not have to sit out a year,
          RME!!! After going to Fort Knox for high school and finally being able
          to play football, I finished out 11th and 12th grade like a golden
          child. Similar to my dad a few years earlier, it was time to close a
          chapter and start a new one. But for me, the next chapter didn't have
          a title.
        </p>
      </div>
    </>
  );
}
