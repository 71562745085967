import PageHeader from "../../shared-components/page-header/page-header";
import React from "react";

export default function College() {
  return (
    <>
      <PageHeader pageTitle={"College"} />
      <div className={"instruction-p"}>
        <p>
          College is supposed to be fun and interesting, if you know where
          you're going. For me, I thought there were only two options: Academic
          Scholarship and pay the rest at Rhodes University or same thing and
          play football at Bethel College (now known as Bethel University). Then
          that day came I met Coach Jernigan. While playing 2 hand touch after
          school with the boys, my high school defensive coordinator at the
          time, Coach Vowell, had called me to the side. He wanted to introduce
          me to his old teammate and good friend Johnny Jernigan. Coach Jernigan
          was the defensive line coach at Murray State University but was in
          the area checking out talent. He told me he had heard plenty good
          about me and wanted to know if I had any college plans. Without
          blinking an eye, I was honest and said, "No sir". He proceeded by
          saying if I get him my tape (film of my highlights), the coaching
          staff will review it and get back to me. I sent a DVD (days before
          Huddle existed) to Coach J as fast as possible. To be stated, this is
          less than a month before national signing day. If I didn't get the
          scholarship, I was more than likely S.O.L. on an athletic scholarship
          and was going to start applying for loans. To God's glory, I received
          a phone call from Murray State extending an offer. Few days later I
          received the FedEx package to commit. My parents finally had no
          worries about me going to school. I was grateful, but I knew the work
          was just beginning. So, I went straight to the gym to prepare for first
          day of camp.
        </p>
        <p>
          I went to Murray as soon as they would allow me on campus. Attending
          workouts and seeing next level talent was eye opening. I sometimes
          didn't feel as if I belonged, so I worked harder. Summer flew by, and
          the school year started. Life as a college student was finally here. I
          enjoyed the freedom and space. I attended classes and practice like I
          was supposed to. I was hitting all the check boxes. Then, the parties.
          I partied like I hit the lotto every weekend. My grades slipped some
          and my parents began to freak out. I managed to stay on track. Then
          after completing the first year of college, I had to mix it up.
          Remember that girl that turned me down, SHE'S BACK! We started dating
          and having the time of our lives that summer. Went back for second
          year of school and football, I had it all and was on top of the world.
          I knew the balances of life, partying, football, and school. So, I
          thought. First semester grades came out, I had a C (maybe 2). Parents
          thought the only change and distraction was the girlfriend. They had
          no insight on the amount of partying. By the way, the coaches who gave
          me the opportunity to come to Murray are now gone. My sister, trying
          to find her way as she starts high school. Life threw me a
          curve ball winter of 2009. But as one door closes, another door shall
          open. The new coach had this phrase he wouldn't stop saying every
          freaking day during 6 AM workouts, "when gives you lemons, make
          lemonade". And after all those workouts, while my mouth is dry as can
          be, the only response I had for this "philosophical monk" was give me
          the lemonade from your horrible life. But as time went on, he
          installed other sayings that I would learn to manage life with. The
          one that stuck with me the most as grades fluctuated, footballs wins
          and losses, and life's rollercoaster ride was "control what you can
          control". Those fights with my parents stating my girlfriend was throwing my
          grades off or those moments sitting in meetings with coaches after the
          third straight loss, I would stick with it, "control what you can
          control". I had no control over my parents' thoughts if they weren't
          going to listen to me, but I did have control over my effort in the
          classroom. I had no control over a team of football players, but I
          could control my growth trajectory and lead by example. So, leaning on
          the lessons I learned as a kid in North Carolina, I closed my mouth
          and let my actions do the talking. I grew into a person who just
          didn't want to talk about a fix, I was going to just do what I needed
          to do and hope for the best outcome. It may not have been the best
          approach, but I was able to tune out the distractions. College had more
          for me, relationships that didn't need to be touched, conversations
          that should've been avoided, and more. After 4 years though it was all
          going to come to an end with just one final year.
        </p>
        <p>
          Final Year, this could be the last time I put on pads to play
          football. This could be the final days being able to call myself a
          student. These were the last days. This year may have been the most
          tumultuous of all the years. Before the season or school year even
          started, I had to answer a question I didn't think I would ever have
          to answer, "do you love football" my coach asked. What type of
          question is that? I had given up time, blood, energy, and more for
          this game over the past 4 years at this school. But you know what, his
          thoughts aren't in my control. So, I took his saying and applied it to
          him, FIDO (ask me and I'll tell you). That year I wanted nothing to do
          with him or anyone. But I needed help from everyone. Why? NFL was
          knocking. NFL scouts were showing up at practice wondering who
          Roderick Tomlin was and what talent he could bring to their team. But
          as a 5th Year Senior, I was struggling in their interviews. I felt
          like the most accomplished failure. They took time out to come find me
          in Murray, but I could barely answer the questions, or at least that's
          what I thought. I remember being asked what player I believe I
          resemble, and I have heard Michael Oher before, but I was over that
          because everyone said the same thing after watching his movie "The
          Blind Side". I stuck with my heart, Jerome "The Bus" Bettis. Not
          what he wanted to hear. But for me, I gave him honesty. It wasn't the
          playing style that I wanted to copy; it was the person. Clean cut and
          mature but humble. The Bus was my role model. He probably left that
          meeting scratching his head. Luckily, we would cross paths again at an
          All-Star game. The year continued, the season was coming to close and
          what better way that to cap it off by proposing. My parents weren't
          for it, but I can't control that. Her parents wanted me to do it the
          right way but had given their blessing. I asked the head coach if I
          could have my girlfriend walk me out during Senior Night then I
          propose, flat no. His response was more in line to have my parents
          escort me. I was pissed. He wanted the same parents that haven't shown
          up to games even though they lived less than 2 hours away to escort me
          over the love of my life. I followed instructions and immediately
          after the game proposed. It left a bitter taste in my mom's mouth, and
          I didn't care. Where did you find me after the fame, swinging from the
          ceilings? Yes. The school year finished in good fashion. I continued to
          spend minimal time with family and continued to pave my life the way I
          wanted. The year finished with more relationship drama of my own, me
          involved with others, and learning how to navigate my path to the NFL.
          I hired an agent and thought life for me was set. Then graduation, I
          fell behind in a class prepping for the NFL that I threw my B.S. in
          Mathematics and Computer Science into jeopardy. After going undrafted
          and signing to Jacksonville Jaguars, I managed to finish my degree
          while in minicamp. College had officially given me all it could throw
          at me and now time to close the chapter.
        </p>
      </div>
    </>
  );
}
