import PageHeader from "../../shared-components/page-header/page-header";
import React from "react";

export default function Nfl() {
  return (
    <>
      <PageHeader pageTitle={"NFL"} />
      <div className={"instruction-p"}>
        <p>
          Life started to calm down being in the NFL. I was doing something few
          had done before, and the attention was all on me. What could go wrong?
          Something, like always. I learned quickly of the level of talent in
          the NFL. The playbook threw my head in circles. The lingo was another
          language. The only thought I had every day was the same as before
          "control what I can control". I started taking extra time to learn
          all I could from the playbook. As well as family, fianc&eacute;, finances, and
          partying. I had the world at my fingertips being an NFL player. I was
          meeting people I never met before. I was attending clubs like a star.
          This wasn't available in Murray (unless your jersey looked like a tank
          top and you played on hardwood instead of turf). Not For Long made
          itself factual very quick. Last game of the preseason and we are
          supposed to be heading to Atlanta, I had received the call all players
          dreaded. I didn't make the cut. I wanted to blame everyone around me.
          But I didn't. I knew I screwed up and would have another chance. Moved
          to Dallas with my fianc&eacute; and began working out. Weeks went by, no
          phone call from any team. Agent was suggesting Canada or AFL. I wasn't
          hearing it. I started tutoring to pass the time, but reality was
          settling in, NFL wasn't knocking anymore. Depression was knocking at
          the door now. Winter came and my biggest accomplishment was working
          for the Dallas Cowboys as an Intern. I was developing an app to help
          the scouting department connect their notes via a database. I liked
          what I was doing but I felt as if school had not prepared me for this.
          The test in school weren't like these real-world problems.
          Relationship was taking the same trajectory as my career path. Then it
          all settled in, 23rd birthday spend sleeping in my car outside
          Applebee's in Grapevine. How did I end up here? Where did I go wrong?
          I'm supposed to be in the NFL, right?
        </p>
        <p>
          I was pissed but finally I realized I had nobody else to be mad at but
          myself. I remember those cold mornings waking up in the car and there
          being frost on the windows. Those mornings going to the YMCA in
          Coppell to play some ball with the old guys and get a shower in before
          work. Yeah, the trip to the YMCA wasn't to play ball, that was just an
          excuse to take a shower before work. Living out my car was my choice
          for that time period. My ex-fianc&eacute; didn't kick me out when she called
          off the wedding. I simply didn't see a purpose to live with each other
          if we weren't together. This was starting to feel like rock bottom.
          When you hit rock bottom do you make a bed out of the cold floor or do
          you start looking up to see where to place the next step. One of those
          cold nights helped me decide. I had nothing, no more NFL calls, and no
          love from anyone it felt like. That's when I realized that I can't
          expect love from others if I don't know how to love myself. I
          started from the ground up. I confessed to my boss, John Winborn, that
          my efforts were lackluster, and I would course correct as well as no longer
          take naps at work. I told my agent I wanted anything. And I told
          myself "I love you" for once. Was it that easy to course correct no. I
          finished my project with the Cowboys and left for Jacksonville to sign
          with the Sharks, an AFL team. Arriving in Jacksonville, I felt like I
          had a second chance. But I knew there was work to be done, especially
          when one of my new teammates is claiming there is a needle in his bed
          at the motel (yes motel, Jaguars = Ritz Carlton while Sharks = EZ
          Motel). I hopped to a couple different positions while playing in the
          AFL, and eventually landed back in Atlanta with the Falcons. Funny
          right, the team I didn't make the cut to play against was now the team
          looking to sign me. Life was course correcting. Working out for the
          Falcons for a week during rookie mini camp, left a good enough
          impression for Bob Kronenberg to give me a call to extend an offer to
          play for the Falcons. I had officially made it. I remember sitting at
          Bush Airport in Houston pondering if I should accept the offer or
          continue with the interview to do IT with the Houston Texans.
          Surprise, I turned down the offer from the Falcons and Texans and
          returned to Alabama to begin working SAIC while I live with parents to
          reset life.
        </p>
      </div>
    </>
  );
}
