import PageHeader from "../../shared-components/page-header/page-header";
import React from "react";

export default function IT() {
  return (
    <>
      <PageHeader pageTitle={"IT"} />
      <div className={"instruction-p"}>
        <p>
          Getting here was tough, but I had learned plenty along the way. I left
          two different loves of my life in order to reset and find me. While
          living in Alabama I ventured down the path of coaching briefly, I
          wasn't ready. The phrase "do unto others as you would want them to do
          unto you" had a different meaning for me. I wanted and expected
          perfection out of myself. I had pushed myself to a point where my life
          was focused round football and solely football. And coaching was the
          way for me to pass the torch onto the next generation so they can
          follow their path? No, I didn't want the next generation to follow
          that path. I didn't want these kids that I had the opportunity to mold
          to end up sleeping in their car like I had. Instead of football
          knowledge, I gave them life knowledge via the form of a football.
          There was an Offensive Lineman I was coaching that had size to sign to
          University of Alabama as a 9th grader, I know because I remember
          looking at him eye to eye when he was in the 9th grade. But he didn't
          have the dog in him to be the beast. I taught him the technique he
          needed in order to improve but I also wanted him to know he should put
          his best foot forward in all he does because you never know who is
          watching. There was a running back who had speed that was like PAC-12
          speed, but he had trouble holding onto the ball. During sprints at the
          end of practice one day I told him to run with the ball and don't lose
          it. He laughed at me. While sprinting across the field, I snuck up
          from behind and popped the ball loose while he was running, then
          continued running and reminded him that every time he dropped the
          ball it was 20 pushups (a wager we made earlier in practice). These
          moments brought back life to me that I had been missing. I was
          inspiring and mentoring others; little did they know, they were
          inspiring me as well. But I had a feeling it wasn't enough because it
          was still focused on football. I needed to hit the drawing board
          again. I wanted to inspire but reach others. The only kids I was
          reaching were the kids at football practice. I wanted to know more
          about technology so I could possibly inspire via tech. The same
          effort I took during that first summer at Murray, those summers when
          the coaches had doubted my love for the game, to find me when I was
          sleeping in the car, was now going to be placed toward advancing my
          career in technology and begin trailblazing a new path for the next
          generation. Coming from where I'm from, technology companies were
          little to nothing in meaning because we only heard of them via tv or
          some news article. The towns I lived in didn't get those endorsement
          checks for a new computer lab or new state of the art technology and
          you get to meet a CEO from Silicone Valley. As a matter of fact, I
          remember my high school aptitude test stated I would be a teacher or
          actuary. I HAD A 34 ON THE MATH SECTION OF THE ACT. Every moment
          possible, I vowed to understand more and more about technology and
          coding. I remember building console applications on CDs and asking
          family to be beta testers. I wasn't going to fail the next generation
          and leave the same paths I had to follow for them. During this process
          of finding the love for technology, I burned some old bridges,
          reignited the love with my ex-fianc&eacute;, built new relationships, and
          began adjusting my growth trajectory within my IT career path. I
          relocated to Dallas/Ft. Worth area and began working for Greyhound
          after holding a position as Tester with SAIC for a year.
        </p>
        <p>
          At Greyhound, I learned the life of corporate America and embraced it
          in my role as IT Support Specialist. It was all familiar since I had a
          crash course being in the NFL, but now I got a different view pointe.
          I jumped at all tickets and didn't back down from any challenge. What
          could be worst then sleeping in my car again on my birthday. But of
          course, life doesn't want me to have the easy route. After living
          together and settling the wrongs from before, my ex-fianc&eacute; and I
          renewed the engagement and promised to focus on us. We knew outside
          forces would want to interfere with our goals, but we had to stay true
          to each other. As we are planning the wedding, she could 
          be promoted if she takes a position in Phoenix. I wasn't losing her
          again. But work saw differently. Left with an ultimatum to choose
          between work and wife, I chose the latter and began searching for a
          new job. I didn't want to lose the job, as I had looked up to my Aunt
          who was able to work for US Steel from the time she graduated high
          school until she retired. But life had other plans. In Phoenix I
          adjusted to the new IT culture. No longer corporate America, but still
          technology. I stumbled learning the new industry but eventually got my
          focus.
        </p>
        <p>
          Along with transitioning life to a new city once again, I was also
          preparing to make one of the biggest transitions of my life, marriage.
          I had given my all to school, football, and other parts of life, but
          now it was time to give my all to my one love. Yes there was plenty
          in my life to make decisions on at this point, but it was all in my
          control. Getting here wasn't easy though. There were hearts broken and
          relationships sacrificed on the road to the engagement. Did I want to
          make those decisions to lose those people? Not at all. But I reminded
          myself many of times, "as a child, I did childish things. As a man, it
          was time to put those childish things away and be a man". By no means
          necessary did I consider these hearts or relationships childish, yet I
          had to stop being childish and decide to focus solely on my
          relationship with my love or focus on my relationships with others.
          For some this may have been easy, but I struggled cutting some of
          these ties. Yes, my love had been there for every win and loss of my
          football career; as well as life's wins and losses. The one's I was
          cutting ties with had also been there when I felt there was
          nothing in life that mattered. For instance, I recall the time in
          college where I had disappeared and lost contact with all around me. I
          was in the middle of college, struggling to find my footing balancing
          life, relationship with my girlfriend and mom, grades were
          satisfactory in my eyes, and broke. I had snapped on twitter earlier
          that day, questioning many parts of my life, including life itself. As
          I sit in my duplex, there was a knock at the door. Two young women
          entered. "Roddy are you ok" one asked. Of course, I lied and enjoyed
          their company until they left 30 minutes later. Those moments were
          tough for me, but those young women had left their mark in my life
          without knowing it. So as I contemplate if I want to cut down my loved
          ones to just a loved one, I struggled. But faith pushed my feet in the
          right direction. I began to cut ties and focus on my love. Some
          lingered longer than others but eventually I left all those loved ones
          behind and focused on just my one love. This focus allowed the
          planning of our wedding to be simple. As the day got closer, the
          anticipation was nearly unbearable. All this work, leading to one day.
          Was I even ready? Didn't know and didn't care. I knew my love and I
          would figure it. We had seen plenty of turmoil that we knew that the
          two of us would work though anything. And that is what we did.
        </p>
        <p>
          September 2016, the morning was cool and crisp. My boys and I sit on
          the patio of this barn house enjoying the morning dew. As we sat there
          on the patio, I see my bride to be preparing for the big day. She's
          already been up 3 hours and she's walking around stressing about
          everything. Not even the grace of the beautiful morning could calm her
          nerves. But did I care? Not one bit. We made it. Unlike many, we
          managed to plan this wedding while also moving our careers to the
          desert. The wedding was paid in full of minimal outside assistance.
          And to top it all off, the few in the wedding party were enjoying
          themselves. It could've rained during the wedding ceremony, that
          wouldn't have mattered to me because I knew after it was all said and
          done, we were married. Nothing could come between us. Did I mention my
          mom was also helping and showing love? We had fought for so many
          years, and finally she came around. She admitted it was tough but she
          wanted to see her son to be happy. Her presence was more love I felt from
          her in years. But I couldn't break down, I had to stay clean and ready
          for my moment to say those two words, I do. When the moment finally
          came, I couldn't help but laugh. The people that had seen us grow up
          from middle school when she had told me no the first time to the
          people that helped us walk across the graduation stage at Murray State
          were all here. It was time. As she walks down the aisle, a beautiful
          butterfly lands near the alter. There hadn't been any butterflies all
          day. Afterwards my mom told me my late uncle loved butterflies. It was
          befitting for him to be there. When others had doubted our
          relationship, he would tell me it doesn't matter what they think, it's
          you and the one you love in a relationship and that's what mattered.
          After the ceremony we partied with everyone and celebrated the union
          of two hearts. As much fun as we were having, work was still waiting.
          It was time to get back to it. A couple days after the wedding, we
          were back on a plane back to the desert to get back to work.
        </p>
        <p>
          Initially in Phoenix I worked for Ready Logistics. This new business
          line as well as trying to continue to push my IT career was a
          whirlwind. I remember being commended by my boss for 
          time I spent learning the system while at the same time being fussed
          at because support wasn't handling enough tickets. Support by the way
          was a one-man team, me. I eventually left my position working in
          support and began working on actual development with a company by the
          name of Advanced Discovery (now Consilio). Once again, a new business
          line. I struggled with the big data queries and XML transformations
          between databases, but my boss was forgiving and knew this was a
          struggle for engineers coming from other industries. I felt the
          support, and in return gave more effort than before. I spent night and
          day learning the business as well as learning technology via
          PluralSight to enhance my career. Over the next 6 months I went
          through the process of my first auto accident with another vehicle
          (which ended with a lawsuit), learning about the struggles of
          pregnancy for women, and the loss of family that was fundamental in my
          growth. But I remembered those kids back in Alabama, so I never backed
          down. Some may say those kids are gone and have forgotten about you.
          That's ok, there are more kids to come looking for a way out other
          than via football. My boss saw my work and applauded my efforts. I was
          on my way, but not there yet. In preparation for the birth of my first
          child, I had to make a tough decision. Leave the boss that had faith
          in me and allowed me to grow or stay with a company that didn't value
          family enough for me to take more than 2 weeks to spend with my
          newborn daughter. I left Consilio and moved on to Integrate.
        </p>
        <p>
          Now at Integrate, I felt loved from the moment I interviewed. But I
          also had another great feeling, feeling of understanding from someone
          else. The CEO was also a former NFL player. He was doing what I
          wanted, running a company. I pulled my chair up and started taking
          notes. It was a burden going to a new industry once again, catch up to
          the speed of their tech stack, and prepare for a newborn coming in the
          next couple of weeks. December 2018, the birth of my daughter was more
          inspirational than any moment in my life. I had witnessed my wife make
          life, have a complicated labor, see my newborn daughter heart rate
          drop, then hold her precious life and feel as if the Earth had finally
          stopped. She changed me. I wanted to give a path to all those kids
          before, but now I also had to make sure whatever path she wanted to
          take was also made available. While at Integrate, I haven't backed
          down from challenges, but I also don't step up to take on every task,
          I've learned that sometimes the best spot in the classroom is at your
          desk with your pencil taking notes and other times it is in front of
          the class leading the lesson. My mentoring ways are still there, but
          instead of reaching out to mentor I just leave my door open. I love
          life as a father and enjoy every moment of the struggle. 30
          years has brought plenty of knowledge and wisdom. I can't wait to see
          what else is to come.
        </p>
      </div>
    </>
  );
}
