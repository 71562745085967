import React from "react";
import PageHeader from "../../shared-components/page-header/page-header";

export default function Wedding() {
  return (
    <>
      <PageHeader
        pageTitle={"The Big Day"}
        pageDescription={"To be continued..."}
      />
    </>
  );
}
